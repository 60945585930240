import * as constants from '../../../Constants';
import ChargeExternalReducer from './ChargeExternalReducer';

const BuildingReducer = (state, action) => {
    switch (action.type) {
        case constants.BUILDING_DIALOG:
            return {
                ...state,
                visibleBuilding: action.value
            };
        case constants.BUILDING_NAME:
            state.buildingData.name = action.value;
            return {
                ...state
            };
        case constants.BUILDING_ADDRESS:
            state.buildingData.address = action.value;    
            return {
                ...state
            };
        case constants.BUILDING_CURRENCY:
            state.buildingData.oCurrency = action.value;
            return {
                ...state
            };
        case constants.BUILDING_PHONE:
            state.buildingData.phone = action.value;    
            return {
                ...state
            };
        case constants.BUILDING_MOBILE:
            state.buildingData.mobile = action.value;    
            return {
                ...state
            };
        case constants.BUILDING_CODE:
            state.buildingData.code = action.value;    
            return {
                ...state
            };
        case constants.BUILDING_TYPE:
            state.buildingData.oType = action.value;     
            return {
                ...state
            };
        case constants.BUILDING_EXECUTION_TYPE:
            state.buildingData.oExecution = action.value;     
            return {
                ...state
            };
        case constants.BUILDING_NOTE:
            state.buildingData.note = action.value;     
            return {
                ...state
            };
        case constants.BUILDING_INTERNAL_NOTE:
            state.buildingData.internalNote = action.value;     
            return {
                ...state
            };
        case constants.BUILDING_CLOSE_DAY:
            state.buildingData.closeDay = action.value;    
            return {
                ...state
            };
        case constants.BUILDING_PAY_DAY:
            state.buildingData.payDay = action.value;    
            return {
                ...state
            };
        case constants.BUILDING_CHECK_ELIMINATED:
            state.buildingData.eliminated = action.value;    
            return {
                ...state
            };
        case constants.COMPANY_BUILDING_LOAD:
            state.buildings = action.value;  
            return {
                ...state
            };
        case constants.BUILDING_DATA_SELECTED:
            state.buildingData = action.value;
            return {
                ...state
            };
        case constants.BUILDING_SELECTED:
            state.building = action.value;
            return {
                ...state,
                selectedBuilding: true
            };
        case constants.BUILDING_SERVICE_NAME:
            if (!state.service){
                state.service = {}
            }
            state.service.description = action.value;   
            return {
                ...state
            };

        case constants.BUILDING_SERVICE_ADD:
            if (state.buildingData.services === undefined){
                state.buildingData.services = [];
            }

            let services = state.buildingData.services.filter(s => s.description === state.service.description);
            if (services.length === 0){
                state.buildingData.services.push(state.service);
                state.service = {};
            }
                
            return {
                ...state
            };
        case constants.BUILDING_SERVICE_ELIMINATED_SAVE:
            let items = state.buildingData.services;
            let index = state.buildingData.services.indexOf(action.value);
            state.buildingData.services = items.slice(0, index).concat(items.slice(index + 1, items.length)); 
            return {
                ...state
            };
        case constants.BUILDING_DASHBOARD_ACCESS_DENIED:
            return {
                ...state,
                access: action.access,
                message: action.message
            };
        case constants.BUILDING_DASHBOARD_REDIRECT:
                return {
                    ...state,
                    redirect: true
                };
        case constants.BUILDING_DASHBOARD_PERIOD_SELECTED:
            return {
                ...state,
                period: action.value
            };
        case constants.BUILDING_DASHBOARD_SEARCH:
            return {
                ...state,
                summary: action.data.value,
                building: action.buildingState,
                rangeEnd: action.data.value.rangeEnd,
                periodMonth: action.data.value.periodMonth,
                periodYear: action.data.value.periodYear
            };
        case constants.BUILDING_ALERTS:
            state.buildingData.alerts = action.value
            return {
                ...state
            }
        case constants.BUILDING_ALERT_PENDING_DAY:
            state.alertPendingPay.delay = action.value
            return {
                ...state
            }
        case constants.BUILDING_ALERT_DEBTOR:
            state.alertDebtor.frecuency = action.value
            return {
                ...state
            }
        case constants.MOVEMENT_ACCOUNT_SEARCH:
            state.accounts = action.value
            return {
                ...state
            }
        case constants.ACCESS_DENIED:
            return {
                ...state,
                access: action.access,
                message: action.message
            };
        case constants.REDIRECT:
            return {
                ...state,
                redirect: true
            };
        case constants.BUILDING_COMUNICATION_SEARCH:
            return {
                ...state,
                comunication: action.value
            }
        default: return ChargeExternalReducer(state, action);
    }
};

export default BuildingReducer;