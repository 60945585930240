import React, {Fragment, useState, useEffect} from 'react';
import {InputText} from 'primereact/inputtext';
import {Button} from 'primereact/button';
import LoadingOverlay from 'react-loading-overlay';
import * as constants from "../../Constants";
import * as action from "./actions/LoginAction";

const AssingPendingDialog = ({ state, dispatch, fn }) => {

    const [loading, setLoading] = useState(false)

    const onContact = () => {
        setLoading(true)
        action.contact(state, dispatch, setLoading)
    }

    return (
        <Fragment>
            <LoadingOverlay
				active={loading}
				spinner
				text='Espere por favor...'>
                <div className="p-fluid">
                    <form className="p-grid p-justify-center" onSubmit={onContact}>
                        <div className="p-md-12">
                            <p>Hola! <b>{state.user.name}</b>, muchas gracias por registrarte en <b>WOLOOLO!</b></p>
                            <p>Para este 2022 tenemos grandes novedades y seguimos sumando muchas funcionalidades en nuestra plataforma.</p>
                            <p>Si tu administración ya trabaja con nosotros, te recomendamos contactarlos para solicitarles que te asocien a tu edificio/barrio y así poder tener acceso a toda la información en tiempo real, ingresos, gastos, novedades, etc.</p>
                            <p>Si tu administración aún no trabaja con nosotros, te pedimos que nos indiques de quien se trata para así ofrecerles nuestro sistema e incorporarse a nuestra plataforma, optimizando al máximo la gestión de tu condominio, lo que repercute directamente en todos los residentes.</p>
                            <p>Que tengas un GRAN 2022 y nos vemos pronto!</p>
                            <p>El equipo de Woloolo.</p>
                        </div>
                        <div className="p-md-12">
                            {
                                state.messageError &&
                                <div className="p-md-12 p-sm-12">
                                    <label style={{fontSize: '11px', fontFamily: 'Lato, sans-serif', color:"red"}}>{state.messageError}</label>
                                </div>
                            }
                            {
                                state.message &&
                                <div className="p-md-12 p-sm-12">
                                    <label style={{fontSize: '11px', fontFamily: 'Lato, sans-serif', color:"#E2841A"}}>{state.message}</label>
                                </div>
                            }
                            <div className="p-md-offset-4 p-md-4 p-sm-12">
                                    <InputText value={state.contact} 
                                        onChange={(e) => dispatch({type: constants.LOGIN_USER_CONTACT, value: e.target.value})} placeholder="Contacto"/>
                                </div>
                            <div className="p-md-offset-4 p-md-4 p-sm-12">
                                <Button label='Enviar' className="p-button-warning" icon="pi pi-chevron-right" iconPos="right" disabled = {state.message || state.messageError}/>
                            </div>
                        </div>
                            
                    </form>
                </div>
                </LoadingOverlay>
        </Fragment>
    );
}

export default AssingPendingDialog;
